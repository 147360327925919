<template>
  <div>

    <section id="game" :class="$parent.questionStateClass"
      v-if="!$parent.ShowVideoLoop && $parent.currQuestionIndex != -1 && $parent.serverState < 3 && $parent.currQuestion != undefined">

      <div class="main has-text-black-bis">

            <h2 v-if="$parent.questionState == 0" class="title is-2">{{ $t('exp_trivia.getReadyToPlay') }}</h2>

            <div v-if="$parent.questionState != 0" class="pregindicator">

              <div class="texto">
                {{ $parent.currQuestionIndex + 1 }}/{{ $parent.selectedQuestions.length }}
              </div>

              <progress-circle class="active"
                :diameter="83"
                :circle-width="7"
                :completed-steps="$parent.clockNormalized * 100"
                :total-steps="100"
                circle-color="#DBDBDB"
                start-color="#FF77C3"
                stop-color="#FF77C3"
                inner-color="#fff"
                :animation-duration="10">
                <span class="has-text-weight-bold">
                  {{ $parent.clockDisplay }}
                </span>
              </progress-circle>

            </div>

            <div v-for="question in $parent.questions" :key="question.video.id">
              <video v-if="$parent.currQuestion.video.id == question.video.id" width="216" class="pregvideo" :ref="`videoQuestion-${question.video.id}`" :src="encodeURI(question.video.url)" autoplay looped muted/>
            </div>

      </div>

      <!-- soreboard -->
      <div style="display:none">
        <div class="score pt-3">
          <div class="header">{{ $t('exp_trivia.score') }}</div>
          <TransitionGroup name="list" class="scoreboard" tag="ul">
            <div v-for="player in $parent.playersScoreboard.slice(0, maxVisibleUsers)" class="item" :key="player.dbid">
              <player-avatar :player="player" :scale="2" :showUsername="false" />
              <span class="username">{{ player.username }}</span>
              <span>
                <animated-number :number="Math.round(player.score)" :duration=".7" />
              </span>
            </div>
          </TransitionGroup>
          <div v-if="$parent.playersScoreboard.length > maxVisibleUsers" class="item-extra-users">
            + {{ $tc('exp_trivia.otherUsers', $parent.playersScoreboard.length - maxVisibleUsers) }}
          </div>
        </div>
      </div>


    </section>

    <Transition name="slidebottom">
      <section class="has-text-black-bis" v-if="$parent.serverState == 3" id="finalScore">
        <!-- Final Screen -->
        
        <template v-if="$parent.playersScoreboard[0].score > 0">
          <!-- Podio -->
          <div class="final scoreboard">
            <div class="header">{{ $parent.playersScoreboard.length == 1 ? $t('exp_trivia.winner') : $t('exp_trivia.firstPlace') }}</div>
            <div class="podium">

              <div class="first">
                <div v-if="$parent.playersScoreboard.length > 0" class="user has-text-weight-bold">
                  <player-avatar :player="$parent.playersScoreboard[0]" :scale="5" :showUsername="false" />
                  <div class="username">{{ $parent.playersScoreboard[0].username }}</div>
                  <div class="score">{{ Math.round($parent.playersScoreboard[0].score) }}</div>
                </div>
              </div>

            </div>
             
            <div class="others mt-3">
              <div v-for="(player, index) in $parent.playersScoreboard.slice(1, 8)" :key="player.dbid" class="item" :class="['pos-' + (index+2)]">
                <div class="rank">{{ $t('exp_trivia.ordinal_n', [index + 2]) }}</div>
                <player-avatar :player="player" :scale="3" :showUsername="false" />
                <div class="username">{{ player.username }}</div>
                <div class="score">{{ Math.round(player.score) }}</div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- Empate -->
          <div class="final scoreboard tie">
            <img src="/assets/trivia/tie.svg">
            <div class="big-title">{{ $t('exp_trivia.deadHeat') }}</div>
          </div>
        </template>
        <canvas id="confetti-canvas"></canvas>
      </section>
    </Transition>

  </div>
</template>

<script>
import gsap from "gsap";
import AnimatedNumber from "../AnimatedNumber.vue";
import { ProgressCircle } from 'vue-progress-circle';
import PlayerAvatar from '../PlayerAvatar.vue';
import "./screenTriviaDefaultGame.scss";

export default {
  components: {
    AnimatedNumber,
    ProgressCircle,
    PlayerAvatar
  },
  data() {
    return {
      maxVisibleUsers: 10,
    };
  },
  computed: {
    preguntaActual() {
      return this.$parent.currQuestion
    }
  },
  watch: {
    preguntaActual(newValue,oldValue){

      console.log('CAMBIO VIEJO',oldValue?.id)
      console.log('CAMBIO NUEVO',newValue?.id)

      // if(oldValue?.id) this.$refs[`videoQuestion-${oldValue.id}`].stop()
      // if(newValue?.id) this.$refs[`videoQuestion-${newValue.id}`].play()

    }
  },
  methods: {
    // Animaciones
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateX(-100px)";
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        duration: 0.8,
        onComplete: done,
        delay: el.dataset.index * 0.7
      });
    },
    leave(el, done) {
      gsap.to(el, {
        opacity: 0,
        x: -20,
        duration: 0.2,
        onComplete: done,
      });
    },
    optionGuessed(answer) {
      return this.$parent.currQuestion.options.find(x => x.id == answer.id).guessed
    }
  }
};
</script>